.card {
    overflow: visible;
    width: 500px;
    height: 400px;
}

.content {
    width: 100%;
    height: 100%;
    transform-style: preserve-3d;
    transition: transform 300ms;
    box-shadow: 0px 0px 10px 1px #000000ee;
    border-radius: 5px;
}

.front, .back {
    background-color: #151515;
    position: absolute;
    width: 100%;
    height: 100%;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    border-radius: 5px;
    overflow: hidden;
}

.back {
    width: 100%;
    height: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
    overflow: hidden;
}

.back::before {
    position: absolute;
    content: ' ';
    display: block;
    width: 160px;
    height: 160%;
    background: linear-gradient(90deg, transparent, #ff9966, #90F2B1, #90F2B1, #ff9966, transparent);
    animation: rotation_481 5000ms infinite linear;
}

.back-content {
    position: absolute;
    width: 99%;
    height: 99%;
    background-color: #151515;
    border-radius: 5px;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
    padding: 20px; /* Add padding to prevent text from sticking to edges */
    box-sizing: border-box; /* Include padding in width and height calculation */
    /*overflow: hidden; !* Hide any overflowing content *!*/
}

.card:hover .content {
    transform: rotateY(180deg);
}

@keyframes rotation_481 {
    0% {
        transform: rotateZ(0deg);
    }

    0% {
        transform: rotateZ(360deg);
    }
}

.front {
    transform: rotateY(180deg);
    color: white;
}

/*.text {*/
/*    position: absolute;*/
/*    width: 100%;*/
/*    height: 100%;*/
/*    padding: 10px;*/
/*    display: flex;*/
/*    flex-direction: column;*/
/*    justify-content: space-between;*/
/*    overflow-wrap: break-word;*/
/*}*/
.title {
    font-size: 30px;
    color: #90F2B1;
}
.front-content .badge {
    background-color: #00000055;
    padding: 2px 10px;
    border-radius: 10px;
    backdrop-filter: blur(2px);
    width: fit-content;
}

/*.description {*/
/*    box-shadow: 0px 0px 10px 5px #00000088;*/
/*    width: 100%;*/
/*    padding: 10px;*/
/*    background-color: #00000099;*/
/*    backdrop-filter: blur(5px);*/
/*    border-radius: 5px;*/
/*}*/

/*.title {*/
/*    font-size: 11px;*/
/*    max-width: 100%;*/
/*    display: flex;*/
/*    justify-content: space-between;*/
/*}*/

/*.title p {*/
/*    width: 50%;*/
/*}*/

.card-footer {
    color: #ffffff88;
    margin-top: 5px;
    font-size: 8px;
}

.front .img {
    position: absolute;
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}

.circle {
    width: 90px;
    height: 90px;
    border-radius: 50%;
    background-color: #90F2B1;
    position: relative;
    filter: blur(15px);
    animation: floating 2600ms infinite linear;
}

#bottom {
    background-color: #000000;
    left: 50px;
    top: 0px;
    width: 150px;
    height: 150px;
    animation-delay: -800ms;
}

#right {
    background-color: #ff2233;
    left: 160px;
    top: -80px;
    width: 30px;
    height: 30px;
    animation-delay: -1800ms;
}

@keyframes floating {
    0% {
        transform: translateY(0px);
    }

    50% {
        transform: translateY(10px);
    }

    100% {
        transform: translateY(0px);
    }
}

.front .text-content {
    max-width: 100%; /* Allow the text to take the maximum width */
    overflow-wrap: break-word; /* Break long words to prevent overflow */
    object-position: center;
    padding: 0 10px 0 0;
    margin-top: 30%;
    margin-left: 10%;
    margin-right:10%;
    color: #90F2B1;
}