/* .how-does-it-workV {
    flex: auto;
} */
.how-does-it-work-container {
    /* flex: auto;
    flex-direction: column; */
    padding: 100px;
}

.how-does-it-work-container1 {
    /* flex: auto; */

    /* Adjust padding as needed */
    display: flex;
    flex-direction: column;
    gap: 6px;
    /* Adjust gap as needed */
    justify-content: center;
    align-items: center;
    margin-bottom: 10%;
}

.division-1 {
    color: #90F2B1;
}

/* 
.how-does-it-work-container2 {
    flex: auto;
    columns: 2;
} */

/* HowDoesItWork.css */

/* HomePageHowDoesItWork.css */

.step-grid {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
}

.steps__step {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 20px;
    border: 1px solid #90F2B1;
    border-radius: 8px;
}

.steps__step-number {
    font-size: 4rem;
    color: #90F2B1;
}

.steps__step-content_p {
    font-size: medium;
}

/* Media query for responsiveness */
@media (max-width: 640px) {
    .step-grid {
        grid-template-columns: 1fr;
    }
}