.find-expert-page {
    display: flex;
    /* flex-direction: column; */
    /* Make the container flex column direction */
    height: 100vh;
    /* Set the height to 100% of the viewport height */
    width: 100vw;
    /* Set the width to 100% of the viewport width */
}

.column-1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 0 0 30%;
    /* Set the first column to take 30% of the available space */
    padding: 20px;
}

.column-2 {
    flex: 1;
    /* Let the second column take the remaining space */
    padding: 20px;
    overflow-y: auto;
    /* Enable vertical scrolling for the expert list */
    border-left: 2px solid #669966;
}

.search-tittle {
    align-content: center;
    font-size: 24px;
    padding: 3%;
    margin-bottom: 10px;
}

.search-tittle p {
    font-size: 40px;
}

.search-box {
    padding: 3%;
    margin-right: 5%;
    font-size: 16px;
}

.expert-list {
    margin-top: 20px;
}

.expert-subcomponent {
    padding: 20px;
    border-radius: 12px;
    border: 2px solid #669966;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    /* background-color: #91642a; */
    margin-bottom: 10px;
    transition: transform 0.7s ease-in-out;
}

.expert-subcomponent:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
    background-color: #2b2821;
}

@media (max-width: 640px) {
    .find-expert-page {
        flex-direction: column;
        /* Change to column layout */
    }

    .column-1,
    .column-2 {
        flex: 1;
        /* Let both columns take full width */
        /* width: 100%; */
        height: 50%;
        /* Set width to full for responsiveness */
    }

    .column-2 {
        order: 2;
        border-top: 2px solid #669966;
    }

}

/* @media (max-width: 375px) {
    .column-1 {
        flex: 0 0 50%;
        /* Set column-1 to take 50% of the available space */
/* } */
/* } */