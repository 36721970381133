.catch-page {
    background-color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    padding: 0 5%;
}

.catch-page h1 {
    font-size: 4.5rem;
    margin-bottom: 20px;
    background: linear-gradient(to right, #eaeaea, #526e59);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.catch-page h2 {
    font-size: 2.5rem;
    margin-bottom: 20px;
    background: linear-gradient(to right, #eaeaea, #b2b2b2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.catch-page h4 {
    margin-top: 5%;
    margin-top: 20px;
    font-size: 1.0rem;
    color: #93969c;
    background: linear-gradient(to right, #93969c, #b2b2b2);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
}

.signup-button {
    padding: 10px 20px;
    font-size: 1.2rem;
    color: #0e0d0d;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    background: linear-gradient(to right, #eaeaea, #526e59);
}

.signup-button:hover {
    background-color: #558855;
}


.email-field {
    /* margin-bottom: 20px; */
}

.email-field input {
    /* width: 100%; */
    border: 1px solid #ccc;
    border-radius: 20px;
    font-size: 1rem;
    margin-right: 10px;
    margin-bottom: 10px;
}

.join-button {
    /* margin-top: 10px; */
    /* padding: 10px 10px; */
    /* display: flex; */
    margin-bottom: 10px;
    font-size: 1.2rem;
    /* color: #0e0d0d; */
    border: none;
    border-radius: 30px;
    cursor: pointer;
    transition: background-color 0.3s ease;

}

.join-button1 {
    margin-right: 10px;
    border-radius: 20px;
    background: linear-gradient(to right, #eaeaea, #526e59);
}

.join-button2 {
    /* margin-right: 10px; */
    align-items: flex-end;
    border-radius: 20px;
    background: linear-gradient(to right, #eaeaea, #526e59);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    border: 2px solid #669966;
    /* background: linear-gradient(to right, #eaeaea, #526e59); */
    /* background-color: linear-gradient(to right, #eaeaea, #526e59); */
    padding: 20px;
    border-radius: 10px;
    text-align: center;
}

.modal-content h2 {
    margin-bottom: 10px;
    -webkit-text-fill-color: linear-gradient(to right, #eaeaea, #526e59);
}

.modal-content button {
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 1rem;
    background: linear-gradient(to right, #eaeaea, #526e59);
}

/* .modal-content button {
    padding: 10px 20px;
    margin-top: 10px;
    font-size: 1rem;
    color: linear-gradient(to right, #eaeaea, #526e59);
    /* color: #ffffff; */
/* border: none; */
/* border-radius: 20px; */
/* cursor: pointer; */
/* transition: background-color 0.3s ease; */
/* } */

/* .modal-content button:hover {
    background-color: #447744;
} */



@media (max-width: 640px) {
    .catch-page h1 {
        font-size: 3.5rem;
        margin-top: 3%;
        margin-bottom: 20px;
        text-align: center;
    }

    .catch-page h2 {
        font-size: 1.5rem;
        margin-bottom: 20px;
        text-align: left;
    }

    .catch-page h4 {
        margin-top: 5%;
        font-size: .5rem;
        text-align: left;
        text-decoration-color: #526e59;
        margin-bottom: 20px;
    }

    .email-field {
        text-align: left;
        margin-right: 10px;
    }

}

/* Error Modal Styles */

.error-modal-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999; /* Ensure it's on top of other content */
}

.error-modal {
    background-color: black;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
}

.error-modal-content {
    text-align: center;
    color: #ffffff;
}

.error-modal button {
    margin-top: 10px;
    background: linear-gradient(to right, #eaeaea, #526e59);

}