/* ExpertSignupPage.css */
body {
    background-color: #1f1f1f;
    /* Matt black background color */
    color: #fff;
    /* White text color */
}

.expert-signup {
    width: 50%;
    margin: auto;
}

.form-group {
    margin-bottom: 20px;
}

label {
    display: block;
    font-weight: bold;
    color: #669966;
    text-align: left;
    /* Dull green color for label */
}

input,
select {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #1f1f1f;
    /* Matt black background color */
    color: #fff;
    /* White text color */
}

input {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.error-message {
    color: red;
    font-size: 14px;
    margin-top: 5px;
}

button[type="submit"] {
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    background-color: #669966;
    /* Dull green color for button background */
    color: #fff;
    /* White text color for button */
    cursor: pointer;
}

button[type="submit"]:hover {
    background-color: #558855;
    /* Darker green color on hover */
}