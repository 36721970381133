/*----- Toggle Button -----*/
.toggle-nav {
    display:none;
}

/*----- Menu -----*/
@media screen and (min-width: 860px) {
    .menu {
        width:100%;
        padding:10px 18px;
        box-shadow:0px 1px 1px rgba(0,0,0,0.15);
        border-radius:3px;
        background:#303030;
    }
}

.menu ul {
    display:inline-block;
}

.menu li {
    margin:0px 50px 0px 0px;
    float:left;
    list-style:none;
    font-size:18px;
}

.menu li:last-child {
    margin-right:0px;
}

.menu a {
    text-shadow:0px 1px 0px rgba(0,0,0,0.5);
    color:#777;
    transition:color linear 0.15s;
}

.menu a:hover, .menu a {
    text-decoration:none;
    color:#fff;
}

/*----- Search -----*/
.menu-buttons {
    float:right;
    display:inline-block;
    margin-right: 15px;
}

.menu-buttons button{
    padding: 8px 16px;
    border: 2px solid transparent;
    border-radius: 4px;
    text-decoration: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: black; /* Set text color to white */
    background:#66a992;
    margin-right: 10px;

}

/*----- Responsive -----*/
@media screen and (max-width: 1150px) {
    .wrap {
        width:90%;
    }
}

@media screen and (max-width: 970px) {
    .menu-buttons input {
        width:120px;
    }
}

@media screen and (max-width: 860px) {
    .menu {
        position:relative;
        display:inline-block;
    }

    .menu ul.active {
        display:none;
    }

    .menu ul {
        width:100%;
        position:absolute;
        top:120%;
        left:0px;
        padding:10px 18px;
        box-shadow:0px 1px 1px rgba(0,0,0,0.15);
        border-radius:3px;
        background:#303030;
    }

    .menu ul:after {
        width:0px;
        height:0px;
        position:absolute;
        top:0%;
        left:22px;
        content:'';
        transform:translate(0%, -100%);
        border-left:7px solid transparent;
        border-right:7px solid transparent;
        border-bottom:7px solid #303030;
    }

    .menu li {
        margin:5px 0px 5px 0px;
        float:none;
        display:block;
    }

    .menu a {
        display:block;
    }

    .toggle-nav {
        padding:20px;
        float:left;
        display:inline-block;
        box-shadow:0px 1px 1px rgba(0,0,0,0.15);
        border-radius:3px;
        background:#303030;
        text-shadow:0px 1px 0px rgba(0,0,0,0.5);
        color:#777;
        font-size:20px;
        transition:color linear 0.15s;
    }

    .toggle-nav:hover, .toggle-nav.active {
        text-decoration:none;
        color:#66a992;
    }

    .search-form {
        margin:12px 0px 0px 20px;
        float:left;
    }

    .search-form input {
        box-shadow:-1px 1px 2px rgba(0,0,0,0.1);
    }
}

/*--------Button css------------*/
.btn-outline {
    background-color: transparent;
    border-color: #669966;
    text-color: white;
}

.btn-outline:hover {
    background-color: #fff;
}

.btn-solid {
    background-color: #669966;
}

.btn-solid:hover {
    background-color: #fff;
}

/*.menu-buttons {*/
/*    display: inline-flex; !* Ensures that the buttons are displayed in a row *!*/
/*    align-items: center; !* Aligns the buttons vertically in the middle *!*/
/*    margin-left: auto; !* Pushes the buttons to the right side of the menu *!*/
/*}*/