.cards-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 20px;
    align-items: center;
}

.card1{
    margin-right: 40%;
}
.card3 {
    margin-right: 40%;
}
.card2 {
    margin-left: 40%;
}
.card4 {
    margin-left: 40%;
}